
require('./date-repeater');
require('./request-trainee-form');
require('./add-trainee-form');
require('./authorisations');
require('./custom-registration');

$(() => {
  $('.clickable-row').on('click', function(e) {
    e.stopPropagation();
    if ($(this).data('target')) {
      window.open($(this).data('href'), $(this).data('target'));
      return;
    }
    window.location = $(this).data('href');
  });

  const form = $('#attestationClientForm');

  $('#attestationClientFormSubmit').on('click', function(ev) {
    ev.preventDefault();
    const expires       = $('#expires').val();
    const training_ids  = $('#training_ids').val();
    
    form.find('input[name="expires"]').val(expires);
    form.find('input[name="training_ids"]').val(training_ids);
    form.trigger('submit');
  });

  const table = $('#attestationClientTable');

  $('#attestationClient_selectNone').on('click', function() {
    $('td input:checkbox',table).prop('checked', false);
  });

  $('#attestationClient_selectAll').on('click', function() {
    $('td input:checkbox',table).prop('checked', true);
  });

  // Show/hide the trainee select based on the radio button
  function toggleSelectDisabled() {
    const allUsersRadio = document.getElementById('all_users');
    const specificUsersRadio = document.getElementById('specific_users');
    const traineeSelect = document.getElementById('trainee_id');

    if (allUsersRadio && specificUsersRadio && traineeSelect) {
      if (allUsersRadio.checked) {
        traineeSelect.setAttribute('disabled', 'true');
      } else {
        traineeSelect.removeAttribute('disabled');
      }

      allUsersRadio.addEventListener('change', toggleSelectDisabled);
      specificUsersRadio.addEventListener('change', toggleSelectDisabled);
    }
  }

  toggleSelectDisabled();
});
